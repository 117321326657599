import React from 'react';

export default function Subscribe() {
return (
<section id="signup" className="signup-section signup-section-des">
  <div className="container">
    <div className="row">
      <div className="col-md-8 col-lg-8 mx-auto text-center justify-content-center">
        <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
        <h2 className="text-white mb-5">Send me a message</h2>

          <form method="POST" action="https://getform.io/f/3bd7edd1-e2e2-4936-a797-d0f71df2d4c0" className="form-inline mx-auto d-flex justify-content-center col-md-12">


              <input type="text" name="name" className="form-control flex-fill mr-0 mr-sm-0 mb-3 mb-sm-2 " required placeholder="Enter Name..."/>
              <input type="email" name="email" className="form-control flex-fill ml-lg-2 mr-0 ml-sm-0 mb-3 mb-sm-2" required placeholder="Enter email..."/>
            <textarea rows="3" type="text" name="message" className="form-control flex-fill mx-auto w-100 mb-2" placeholder="Enter Message..." required></textarea>


          <button type="submit" className="btn btn-primary w-100 p-3">
            Send
          </button>

        </form>
      </div>
    </div>
  </div>
</section>

);
}
