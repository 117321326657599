import React from 'react';
import { Gallery } from "gatsby-theme-gallery";


import Layout from '../components/Layout';


import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';
import Contact from '../components/Contact-des';

import avatar from '../assets/images/fortona.png';


import des from '../assets/images/design-logo-white.png';




const IndexPage = () => (
<Layout>
  <Header />


  <header className="mast-design masthead">
  <div className=" d-flex h-100 align-items-center">
    <div className="container d-flex h-100 align-items-center">
      <div className="row mx-auto justify-content-center no-gutters mb-5 mb-lg-12">
        <div className="col-lg-11">
          <h1 className="mx-auto my-0 des-h1">{config.hpref}.design</h1>
        </div>
        <div className="col-lg-1 justify-content-right">
          <img className="des-pico" src={des} alt="" />
        </div>
      </div>
    </div>
  </div>
  </header>


  <section id="p1" className="des-one text-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-11 mx-auto ">
          <h2 className="mb-4">fortona sans</h2>
        </div>
      </div>
      <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
        <div className="col-lg-8">
          <p className="text marg-rig">
            My first font. <br /><br />
            Made in Adobe Illustrator using the fontself extension. While making
             fortona sans, I learnt a lot about typeface design. <br /><br /> There are some
              things I would like to fix such as the number “5” but I don’t
              think I’ll work on fortona again. (I’m working on a new font) <br /><br />
            You can get it
            <a className="" href="/downloads"> here.</a>
          </p>
        </div>
        <div className="col-lg-3">
          <img className="img-fluid fix-img pad-left" src={avatar} alt="" />
        </div>
      </div>
    </div>
  </section>


<div className="container space-me des-gallery">
<h2>works</h2>
<Gallery />
</div>
  <Contact />
  <SocialLinks />
  <Footer />
</Layout>
);

export default IndexPage;
